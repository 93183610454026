import request from '@/utils/request'


// 查询搜索词列表
export function listSearch(query) {
  return request({
    url: '/video/search-terms/list',
    method: 'get',
    params: query
  })
}

// 查询搜索词分页
export function pageSearch(query) {
  return request({
    url: '/video/search-terms/page',
    method: 'get',
    params: query
  })
}

// 查询搜索词详细
export function getSearch(data) {
  return request({
    url: '/video/search-terms/detail',
    method: 'get',
    params: data
  })
}

// 新增搜索词
export function addSearch(data) {
  return request({
    url: '/video/search-terms/add',
    method: 'post',
    data: data
  })
}

// 修改搜索词
export function updateSearch(data) {
  return request({
    url: '/video/search-terms/edit',
    method: 'post',
    data: data
  })
}

// 删除搜索词
export function delSearch(data) {
  return request({
    url: '/video/search-terms/delete',
    method: 'post',
    data: data
  })
}
